// -----------------------------------//
// Variables
// -----------------------------------//

// gold #92834a
// gold dark #7c6f3d
// green #44211d
// green dark #6c322e

// -----------------------------------//
// Sup Nav colours
// -----------------------------------//
$sup-menu-bg: #e9a126;
$sup-menu-highlight: #c3861b;
$sup-menu-colour: #fff;

// -----------------------------------//
// Nav Menu colours
// -----------------------------------//
$menu-background-bg: #44211d;
$menu-text-colour: #fff;
$sub-menu-bg: #6c322e;

// Hovers
$menu-hover: #6c322e;
$sub-menu-hover: #44211d;

//MEANMENU
$mm-reveal-background: #6c322e;
$mm-navigation-background: #1c593d;

// -----------------------------------//
// HEADER LOGO SECTION
// -----------------------------------//
$header-background: #fff;
$logo-colour: #92834a;

// -----------------------------------//
// NEWS DIARY SECTION
// -----------------------------------//

$scroll-hvr: #c3861c; //twitter scrollbar hover
$date-bg: #c3861c; // diary date bg
$date-box-bg: #e9a126; // diary entry bg

// buttons
$btn-bg: #44211b;
$btn-hvr: #fff;
 
// -----------------------------------//
// FOOTER colours
// -----------------------------------//
$footer-bg: #fff;
$footer-colour: #eaa025;
$footer-link: #eaa025;
$footer-link-hvr: #486155;
$footer-register: #486155;

//subfooter
$subfooter-bg: #44211d;
$subfooter-link: #fff;
$subfooter-link-hvr: #fff;


// -----------------------------------//
// INSIDE PAGES
// -----------------------------------//
$link-color: deepskyblue;
$h1-color: #44211d;
$h2-color: #d49424;
$h3-bg-color:#44211d;
$h4-bg-color:#d49424;

//Related pages
$related-pages-h2:#fff;
$related-pages-bg: #e9a126;
$related-pages-hvr:#d49424;


//News
$little-date-line: #44211d;

//Subscribe
$subscribe-bg-colour: #44211d;

//Diary list inside page colours
$diary-date-bg: #44211d;
$add-to-calendar-bg: #44211d;

//Gallery
$gallery-bg: #44211d;

//Photo box text colours (DARK background)
// Date
$box-light-date: #dbed2b;

//Photo box text colours (LIGHT background)
// Date
$box-dark-date: #44211d;

//Grid and List view styles
h2.ListingHead {
	background: #44211d;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #44211d;
$search-highlight-color: #fff;

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//
$table-highlight-color: #fff;
$table-highlight-background-color: #44211d;
$table-border-color: #44211d;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//
$blockquote-background-color: #fff;
$blockquote-color: #44211d;
$blockquote-speech-mark-color: #92834a;
$blockquote-border-color: #92834a;